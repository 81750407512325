// import BackButton from "../../Buttons/BackButton";
import NextButton from "../../Buttons/NextButton";
import { Link } from "react-router-dom";

const Words = ({
  data,
  redirect,
  handleSkipAll,
  setDifficulty,
  difficulty,
  practiceText,
  day,
  gptRedirect,
}) => {
  //for practice words map

  const WordsMapping = data.map((el, i) => {
    let difficultyColor;
    switch (el.difficulty) {
      case "B":
        difficultyColor = "bg-[#000]";
        break;
      case "E":
        difficultyColor = "bg-[#AA7C48]";
        break;
      case "U":
        difficultyColor = "bg-[#808080]";
        break;
      default:
        difficultyColor = "";
    }

    return (
      //  onClick={() => handleClick(i)}
      <div key={i}>
        {" "}
        <Link
          to={`/glibzterAI?word=${el.word}&to=${redirect}&day=${day}&gptredirect=${gptRedirect}`}
        >
          <div
            className={`${difficultyColor} font-bold shadow-lg text-white 5xl:text-[18px] 4xl:text-[14px] 3xl:text-[13px] ss:text-[12px] text-xs 5xl:py-[24px] 4xl:py-[19px] 3xl:py-[18px] ss:py-[16px] py-[16px] rounded-[12px] text-center 5xl:mb-[24px] 4xl:mb-[19px] 3xl:mb-[18px] ss:mb-[16px] mb-[16px]
            `}
          >
            {el.word}
          </div>
        </Link>
      </div>
    );
  });
  function handleClick() {
    if (difficulty === "B") setDifficulty("all");

    if (difficulty !== "B") setDifficulty("B");
  }

  return (
    <main className="5xl:p-[48px] 4xl:p-[43px] 3xl:p-[36px] ss:p-[32px] p-[32px] bg-white rounded-[12px] ">
      {/* <section>
        <div className="flex justify-between">
          <p>0</p>
          <p>5</p>
          <p>10</p>
        </div>
        <div className="h-[15px] bg-[#F6EEE5] rounded-[50px] flex flex-row justify-start">
          {" "}
          <div className="w-[50%] bg-[#AA7C48] rounded-[50px] transition-all duration-300 ease-out"></div>
        </div>
      </section> */}
      <section className="flex justify-between  items-center 5xl:pb-[48px] 4xl:pb-[43px] 3xl:pb-[36px] ss:pb-[32px] pb-[32px]">
        <div className="5xl:text-[22px] 4xl:text-[18px] 3xl:text-[16px] ss:text-[14px] text-[14px] font-bold ">
          {data.length > 0 ? practiceText : `No ${gptRedirect}s to practice`}
        </div>
        {!day && (
          <div className="flex items-center">
            <p className="me-[6px]">Basic</p>
            <div
              onClick={handleClick}
              className={`cursor-pointer ${
                difficulty === "B"
                  ? "border-transparent bg-[#000] py-[6px] px-[9px] "
                  : "border-black border-[1px] py-1 px-2"
              }   
                rounded-[12px] 4xl:rounded-[16px] flex `}
            >
              <div
                className={`w-[12px] h-[12px rounded-full border-[1px] border-black ${
                  difficulty === "B" && "invisible"
                }`}
              ></div>
              <div
                className={`w-[12px] h-[12px]  rounded-full bg-[white] ${
                  difficulty !== "B" && "invisible"
                }`}
              ></div>
            </div>
          </div>
        )}
      </section>

      <section className="">{WordsMapping}</section>
      <section className="pt-[24px]  flex justify-end">
        {/* <BackButton
          // handlePostRequest={handlePostRequest}
          handleClick={handleSkipAll}
        /> */}
        <NextButton
          // handlePostRequest={handlePostRequest}
          handleClick={handleSkipAll}
        />
      </section>
    </main>
  );
};

export default Words;

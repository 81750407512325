// App.js
import React, { useState } from "react";
import translateText from "../../util/translateText";
const Language = [
  {
    value: "hi-IN",
    lang: "Hindi",
  },
  {
    value: "ta-IN",
    lang: "Tamil",
  },

  {
    value: "te-IN",
    lang: "Telugu",
  },
  {
    value: "ml-IN",
    lang: "Malayalam",
  },
  {
    value: "kn-IN",
    lang: "Kannada",
  },
  {
    value: "bn-IN",
    lang: "Bangla",
  },
  ,
  {
    value: "mr-IN",
    lang: "Marathi",
  },
];
function Translate({ Text, setoutputText }) {
  const [targetLanguage, setTargetLanguage] = useState("hi-IN");
  const handleTranslate = async () => {
    const translatedText = await translateText(Text, targetLanguage);
    setoutputText(translatedText);
  };

  return (
    <div>
      <button
        className="5xl:text-[14px] 4xl:text-[13px] 3xl:text-[12.5px] ss:text-[11px] text-[11px] text-white rounded-[36px] bg-[#AA7C48] p-[8px] me-[10px]"
        onClick={handleTranslate}
      >
        Translate
      </button>
      <select
        className="p-[8px] rounded-[6px] 5xl:text-[14px] 4xl:text-[13px] 3xl:text-[12.5px] ss:text-[11px] text-[11px]"
        value={targetLanguage}
        onChange={(e) => setTargetLanguage(e.target.value)}
      >
        {Language.map((val, ind) => {
          return (
            <option key={ind} value={val.value}>
              {val.lang}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Translate;
